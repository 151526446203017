class FooterComponent extends HTMLElement {
  connectedCallback() {
    this.innerHTML = String.raw`
    <footer id="footer" style="background:#f7fbfe;">
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6 footer-contact">
              <p><a href="eula.html">Terms of Service</a></p>
              <p><a href="mailto:jeff@webstructural.com">Support</a></p>
            </div>

            <div class="col-lg-4 col-md-6 footer-links">
              <h4>Software & Tools</h4>
              <ul style="list-style-type:none;">
                <li><i class="fa fa-chevron-right"> </i><a href="beam-designer.html"> Beam Designer</a></li>
                <li><i class="fa fa-chevron-right"> </i><a href="anchor-bolt-designer.html"> Anchor Bolt Designer</a></li>
                <li><i class="fa fa-chevron-right"> </i><a href="footing-designer.html"> Concrete Footing Designer</a></li>
                <li><i class="fa fa-chevron-right"> </i><a href="shear-and-moment-diagram.html"> Shear and Moment Diagrams</a></li>
                <li><i class="fa fa-chevron-right"> </i><a href="beam-calculator.html"> Beam Calculator</a></li>
                <li><i class="fa fa-chevron-right"> </i><a href="cross-section-calculator.html"> Shape Calculator</a></li>
              </ul>
            </div>
            
            <div class="col-lg-4 col-md-6 footer-links">
              <h4>Tutorials</h4>
              <ul style="list-style-type:none;">
                <li><i class="fa fa-chevron-right"> </i><a href="steel-beam-how-to.html"> How to Design a Steel Beam</a></li>
                <li><i class="fa fa-chevron-right"> </i><a href="save-steel-and-wood-beam-designs.html"> How to Save a Beam Design</a></li>
                <li><i class="fa fa-chevron-right"> </i><a href="#"> How to Design an Anchor Bolt - Coming Soon</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="container d-md-flex py-4" style="background:#f7fbfe;">
        <div class="me-md-auto text-center text-md-start">
          <div class="copyright">
            &copy; <strong><span>WebStructural 2022</span></strong>
          </div>
        </div>
      </div>
    </footer><!-- End Footer -->
      `
  }
}

customElements.define('footer-component', FooterComponent);