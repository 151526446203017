class BasicNavBarComponent extends HTMLElement {
  
  connectedCallback() {
    this.innerHTML = String.raw`
    <nav class="navbar navbar-expand-lg navbar-light fixed-top py-0 shadow-sm bg-light hidden-print">
    <div class="container-fluid">
      <a class="navbar-brand" href="#" role="button" style="padding-top:14px;padding-bottom:14px;">${this.getAttribute('title')}</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div id="project-toast"
          class="toast align-items-center text-white bg-primary top-0 start-50 translate-middle-x" role="alert"
          aria-live="assertive" aria-atomic="true">
          <div class="d-flex">
            <div class="toast-body" id='project-toast-body'></div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
              aria-label="Close"></button>
          </div>
        </div>

        <ul class="nav ms-auto mb-2 mb-lg-0 align-middle" style="margin-right:10px;" id="">
          <li class="nav-item dropdown dropstart">
            <a class="btn btn-default dropstart navbar-btn hidden-print" type="button" href="#" id="apps-dropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Apps <i class="fa fa-th fa-fw"></i>
            </a>
            <ul class="dropdown-menu" aria-labelledby="apps-dropodown">
              <li class="text-center"><a class="btn btn-default" style="margin:5px;" href="https://webstructural.com/beam-designer.html">Steel & Wood Beam Design</a></li>
              <li class="text-center"><a class="btn btn-default" style="margin:5px;" href="https://webstructural.com/shear-and-moment-diagram.html">Shear & Moment Diagrams</a></li>
              <li class="text-center"><a class="btn btn-default" style="margin:5px;" href="https://webstructural.com/anchor-bolt-designer.html">Anchor Bolt Designer</a></li>
              <li class="text-center"><a class="btn btn-default" style="margin:5px;" href="https://webstructural.com/footing-designer.html">Footing Designer</a></li>
              <li class="text-center"><a class="btn btn-default" style="margin:5px;" href="beam-calculator.html">Beam Calculator</a></li>
              <li class="text-center"><a class="btn btn-default" style="margin:5px;" href="cross-section-calculator.html">Cross Section Calculator</a></li>
            </ul>
          </li>
        </ul>

      </div>
    </div>

  </nav>
      `
  }
}

customElements.define('basic-nav-bar-component', BasicNavBarComponent);
